import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allStrapiContact {
        nodes {
          email
          latitude
          longitude
          phone
          fax
          extensions {
            arabicName
            id
            name
            value
          }
        }
      }
    }
  `).allStrapiContact.nodes[0]

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div>
        <div>
          <h3 className="heading">Our Location</h3>
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="500"
                id="gmap_canvas"
                src={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              <a href="https://www.whatismyip-address.com/divi-discount/"></a>
            </div>
          </div>
          <h3 className="subheading">
            You are welcome to call us on the following numbers
          </h3>
          <p style={{ fontSize: "12pt" }}>
            <span style={{ width: "100px", display: "inline-block" }}>
              Phone
            </span>
            {data.phone} <br />
            <span style={{ width: "100px", display: "inline-block" }}>Fax</span>
            {data.fax}
          </p>
          <h3 className="subheading">
            Followed by the following extensions for the departments you wish to
            contact
          </h3>
          <p style={{ fontSize: "12pt" }}>
            {data.extensions.map(extension => (
              <div key={extension.id}>
                <span style={{ width: "300px", display: "inline-block" }}>
                  {extension.name}
                </span>
                {extension.value}
              </div>
            ))}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
